<template>
  <el-form ref="form" :model="form">
    <th-wrapper
      :title="$t('pages.settings.gastronomy.kitchen_printers.title')"
      :info="$t('pages.settings.gastronomy.kitchen_printers.tooltip')"
    >
      <!-- Enable -->
      <template #center>
        <el-checkbox v-model="enableKitchenPrinters">
          {{ $t('common.forms.labels.active') }}
        </el-checkbox>
      </template>

      <!-- Printers -->
      <div
        v-for="(item, index) in printers"
        :key="index"
        class="mb-4 last:mb-0"
      >
        <div class="flex mb-1">
          <!-- Title -->
          <el-form-item
            :prop="`title-${index}`"
            :label="
              $t('pages.settings.gastronomy.kitchen_printers.form.title.label')
            "
            :rules="titleRequired"
            class="w-1/3 pr-5 mb-0"
          >
            <el-input
              :id="`title-${index}`"
              :model-value="item.title"
              @input="setValue(index, 'title', $event)"
            />
          </el-form-item>

          <!-- Tags -->
          <el-form-item
            prop="tag_ids"
            :label="
              $t('pages.settings.gastronomy.kitchen_printers.form.tags.label')
            "
            class="w-1/2 pr-5 mb-0"
          >
            <el-select
              id="tag_ids"
              v-cancel-read-only
              :model-value="item.tag_ids"
              filterable
              clearable
              multiple
              class="w-full"
              :placeholder="
                $t(
                  'pages.products.edit.form.sections.additional.tags.placeholder'
                )
              "
              @change="setValue(index, 'tag_ids', $event)"
            >
              <el-option
                v-for="tag in tagsList"
                :key="tag.value"
                :label="tag.label"
                :value="tag.value"
              />
            </el-select>
          </el-form-item>

          <el-form-item label="Actions" class="hidden-label mb-0">
            <!-- Delete -->
            <el-button
              plain
              icon="Delete"
              class="-ml-3 el-button--text-icon"
              @click="deleteItem(index)"
            />

            <!-- Add -->
            <el-button
              v-if="printers.length === index + 1"
              :aria-label="
                $t(
                  'pages.settings.gastronomy.kitchen_printers.form.plus_printer.label'
                )
              "
              plain
              class="el-button--primary-icon ml-2"
              icon="Plus"
              @click="addNewItem"
            />
          </el-form-item>
        </div>

        <div class="flex">
          <!-- Print cancelation -->
          <el-form-item v-if="item.triggers" class="w-1/3">
            <el-switch
              :model-value="item.triggers.booking"
              data-testid="booking"
              active-value="delta"
              inactive-value="additions"
              :active-text="
                $t(
                  'pages.settings.gastronomy.kitchen_printers.print_cancellations.text'
                )
              "
              @change="setValue(index, 'triggers', $event, 'booking')"
            />
          </el-form-item>

          <!-- Print size -->
          <el-form-item>
            <el-switch
              :model-value="item.size_type ? item.size_type : printSizes.LARGE"
              data-testid="sizeType"
              :active-value="printSizes.LARGE"
              :inactive-value="printSizes.REGULAR"
              :active-text="
                $t('pages.settings.gastronomy.kitchen_printers.print_size.text')
              "
              @change="setValue(index, 'size_type', $event)"
            />
          </el-form-item>
        </div>
      </div>
    </th-wrapper>
  </el-form>
</template>

<script>
import safeGet from 'just-safe-get'

const printSizes = { LARGE: 'large', REGULAR: 'regular' }

function genInitial() {
  return {
    title: null,
    pager: true,
    size_type: printSizes.LARGE, //by default the print size is large
    tag_ids: [],
    triggers: {
      sale: false,
      booking: 'delta'
    }
  }
}

/*
The fields in this component get their state from the vuex store. As a normal get() and set() cannot work to modify specific elements
in an array, :value and @input are used here. The data is not stored locally in this component, as syncing with the store would be redundant and error prone.
*/
export default {
  data() {
    return {
      // To be able to validate the full form is necesary a model, even if this one is not used
      form: {},
      tagsList: [],
      titleRequired: [{ validator: this.validateRequired, trigger: 'blur' }],
      printSizes
    }
  },
  computed: {
    printers() {
      return safeGet(
        this.$store.getters['Config/getClientAccountConfiguration'],
        'receipts.order_receipt.receipts'
      )
    },
    orderReceipt() {
      return safeGet(
        this.$store.getters['Config/getClientAccountConfiguration'],
        'receipts.order_receipt'
      )
    },
    enableKitchenPrinters: {
      get: function () {
        return safeGet(
          this.$store.getters['Config/getClientAccountConfiguration'],
          'receipts.order_receipt.enabled'
        )
      },
      set: function (newValue) {
        this.$store.dispatch('Config/setClientAccountConfigurationValue', {
          path: 'receipts.order_receipt.enabled',
          value: newValue || false
        })
      }
    }
  },
  watch: {
    /* This watcher is important, because it triggers the function initialize() in 3 scenarios:
    1. When the component is created
    2. On reloading of the page a race condition exists between re-fetching the configurations and created(). The re-fetched data will often override the data after initialize().
    3. After the general "reset" from the settings toolbar is triggered
    */
    printers: {
      handler: function () {
        this.initialize()
      },
      deep: true
    }
  },
  async created() {
    this.initialize()
    await this.fetchTags()
  },
  methods: {
    async fetchTags() {
      const { tags } = await this.$resourceFetch('tags')
      this.tagsList = tags?.map((tag) => {
        return {
          value: tag.id,
          label: tag.name
        }
      })
    },

    // Always initialize receipts with at least one element
    // so that the user always sees at least one set of initial fields
    initialize() {
      const initial = {
        enabled: false,
        maxReceiptNumber: 100,
        receipts: [genInitial()]
      }
      // Since some values are being set on the config object on component mount,
      // the config is being set to dirty from the beginning and will prevent navigating away from the page
      // although the user didn't made any actual action to the form. Therefore, we're toggling the dirty state to false.
      if (!this.orderReceipt) {
        this.$store.dispatch('Config/setClientAccountConfigurationValue', {
          path: 'receipts.order_receipt',
          value: initial
        })
        this.$store.dispatch('Config/syncClientAccountConfiguration')
        return
      }

      if (!this.printers?.length) {
        this.$store.dispatch('Config/setClientAccountConfigurationValue', {
          path: 'receipts.order_receipt',
          value: {
            ...this.$deepClone(this.orderReceipt),
            ...initial
          }
        })
        this.$store.dispatch('Config/syncClientAccountConfiguration')
        return
      } else {
        // Add triggers to printer config
        this.printers.forEach((p) => {
          if (!p.triggers) {
            p.triggers = genInitial().triggers
          }
        })
      }
    },
    // mutate receipts in the store
    updateStore(value) {
      this.$store.dispatch('Config/setClientAccountConfigurationValue', {
        path: 'receipts.order_receipt.receipts',
        value
      })
    },
    // update "title" and "tag_ids" in the correct object and send it to the store
    setValue(index, path, value, subpath) {
      let order = this.$deepClone(this.orderReceipt)
      if (subpath) order.receipts[index][path][subpath] = value
      else order.receipts[index][path] = value

      this.$store.dispatch('Config/setClientAccountConfigurationValue', {
        path: 'receipts.order_receipt',
        value: order
      })
    },
    // add a new object to the receipts array
    addNewItem() {
      let data = this.$deepClone(this.printers)
      if (Array.isArray(data)) {
        data.push(genInitial())
      } else {
        data = [genInitial()]
      }
      this.updateStore(data)
    },
    // delete an item from the array, but keep at least one element for display
    deleteItem(index) {
      let data = this.$deepClone(this.printers)
      if (this.printers.length === 1) {
        data = [genInitial()]
      } else {
        data.splice(index, 1)
      }
      this.updateStore(data)
    },
    validate(cb) {
      this.$refs.form.validate((valid) => {
        if (valid) this.cleanForm()
        return cb(valid)
      })
    },
    validateRequired(rule, _, callback) {
      const index = rule?.field?.split('-')[1] || 0
      // Min length 3 chars
      // Required if we select some tag
      if (
        (!this.printers[index].title && this.printers[index].tag_ids.length) ||
        (this.printers[index].title && this.printers[index].title.length < 3)
      ) {
        callback(
          new Error(
            this.$t(
              `pages.settings.gastronomy.kitchen_printers.form.title.error`
            )
          )
        )
      } else {
        callback()
      }
    },
    // Delete all empty items, to be able to save
    cleanForm() {
      let data = []
      this.printers.forEach((item, index) => {
        if (item.title) {
          data.push(item)
        }
      })
      this.updateStore(data)
    }
  }
}
</script>
