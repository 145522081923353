<template>
  <section>
    <orders />
    <balances />
    <guest-pagers v-if="!isPagersDisabled" />
    <kitchen-printers v-if="!isPrintersDisabled" ref="printers" />
  </section>
</template>

<script>
import GuestPagers from './components/guest-pagers'
import KitchenPrinters from './components/kitchen-printers'
import Orders from './components/orders'
import Balances from './components/balances'
import { storeToRefs } from 'pinia'
import { useAppConfigStore } from '@/store/app-config'

export default {
  components: {
    GuestPagers,
    KitchenPrinters,
    Orders,
    Balances
  },

  setup() {
    const appConfigStore = useAppConfigStore()
    const { featureConfig } = storeToRefs(appConfigStore)
    return { featureConfig }
  },

  computed: {
    isPagersDisabled() {
      return (
        !this.featureConfig.settings?.pos?.pagers ||
        this.featureConfig.settings?.pos?.pagers === 'false'
      )
    },
    isPrintersDisabled() {
      return (
        !this.featureConfig.settings?.pos?.kitchen_printers ||
        this.featureConfig.settings?.pos?.kitchen_printers === 'false'
      )
    }
  },

  methods: {
    validate(cb) {
      if (!this.isPrintersDisabled) {
        this.$refs.printers.validate((valid) => {
          return cb(valid)
        })
      } else {
        cb(true)
      }
    }
  }
}
</script>
