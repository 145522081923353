<template>
  <th-wrapper :title="$t('pages.settings.pos.gastronomy.balances.title')">
    <div class="mb-6">
      <th-input-title
        class="mb-2"
        :title="
          $t('pages.settings.pos.gastronomy.balances.open_orders_policy.title')
        "
      />

      <el-radio-group v-model="openOrdersPolicy" size="small">
        <div class="flex flex-col">
          <el-radio label="ignore">
            {{
              $t(
                'pages.settings.pos.gastronomy.balances.open_orders_policy.ignore'
              )
            }}
          </el-radio>
          <el-radio label="warning">
            {{
              $t(
                'pages.settings.pos.gastronomy.balances.open_orders_policy.warning'
              )
            }}
          </el-radio>
          <el-radio label="block">
            {{
              $t(
                'pages.settings.pos.gastronomy.balances.open_orders_policy.block'
              )
            }}
          </el-radio>
        </div>
      </el-radio-group>
    </div>
  </th-wrapper>
</template>

<script>
import get from 'just-safe-get'

export default {
  computed: {
    openOrdersPolicy: {
      get() {
        return get(
          this.$store.getters['Config/getClientAccountConfiguration'],
          'settings.balances.open_orders_policy',
          'ignore'
        )
      },
      set(newValue) {
        this.$store.dispatch('Config/setClientAccountConfigurationValue', {
          path: 'settings.balances.open_orders_policy',
          value: newValue
        })
      }
    }
  }
}
</script>
