<template>
  <th-wrapper
    :title="$t('pages.settings.gastronomy.guest_pagers.title')"
    :subtitle="$t('pages.settings.gastronomy.guest_pagers.subtitle')"
  >
    <!-- Use pagers -->
    <div class="mb-4">
      <el-switch
        id="manual_trigger_pagers"
        v-model="manualTriggerEnabledPagers"
        :active-text="
          $t('pages.settings.gastronomy.switches.enabled_pagers.text')
        "
      />
    </div>

    <!-- Prompt cashier -->
    <div>
      <el-switch
        id="manual_trigger_prompt"
        v-model="manualTriggerEnabledPrompt"
        :active-text="
          $t('pages.settings.gastronomy.switches.enabled_prompt.text')
        "
      />
    </div>
  </th-wrapper>
</template>
<script>
import get from 'just-safe-get'

export default {
  computed: {
    manualTriggerEnabledPagers: {
      get: function () {
        return get(
          this.$store.getters['Config/getClientAccountConfiguration'],
          'features.pagers.enabled'
        )
      },
      set: function (newValue) {
        this.$store.dispatch('Config/setClientAccountConfigurationValue', {
          path: 'features.pagers.enabled',
          value: newValue || false
        })
        this.$store.dispatch('Config/setClientAccountConfigurationValue', {
          path: 'features.pagers.max',
          value: newValue ? 100 : null
        })
      }
    },
    manualTriggerEnabledPrompt: {
      get: function () {
        return get(
          this.$store.getters['Config/getClientAccountConfiguration'],
          'features.pagers.prompt'
        )
      },
      set: function (newValue) {
        this.$store.dispatch('Config/setClientAccountConfigurationValue', {
          path: 'features.pagers.prompt',
          value: newValue || false
        })
      }
    }
  }
}
</script>
