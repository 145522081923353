<template>
  <th-wrapper
    :title="$t('pages.settings.gastronomy.orders.title')"
    :subtitle="$t('pages.settings.gastronomy.orders.subtitle')"
  >
    <div class="flex items-center mb-4">
      <!-- Lock to waiters -->
      <el-switch
        v-model="lockToWaiter"
        :active-text="
          $t('pages.settings.gastronomy.switches.lock_to_waiter.text')
        "
      />
    </div>
    <div class="flex items-center mb-4">
      <!-- Require PIN on order reassignment -->
      <el-switch
        v-model="requiresPin"
        :active-text="
          $t('pages.settings.gastronomy.switches.requires_pin.text')
        "
      />
    </div>
    <div class="flex items-center">
      <!-- Allow printing of receipt previews -->
      <el-switch
        v-model="previewPrinting"
        :active-text="
          $t('pages.settings.gastronomy.switches.preview_printing.text')
        "
      />
    </div>
  </th-wrapper>
</template>

<script>
import get from 'just-safe-get'

export default {
  computed: {
    lockToWaiter: {
      get: function () {
        return get(
          this.$store.getters['Config/getClientAccountConfiguration'],
          'gastro.orders.lock_to_waiters'
        )
      },
      set: function (newValue) {
        this.$store.dispatch('Config/setClientAccountConfigurationValue', {
          path: 'gastro.orders.lock_to_waiters',
          value: newValue || false
        })
      }
    },
    requiresPin: {
      get: function () {
        return get(
          this.$store.getters['Config/getClientAccountConfiguration'],
          'gastro.orders.reassignment.requires_pin'
        )
      },
      set: function (newValue) {
        this.$store.dispatch('Config/setClientAccountConfigurationValue', {
          path: 'gastro.orders.reassignment.requires_pin',
          value: newValue || false
        })
      }
    },
    previewPrinting: {
      get: function () {
        return get(
          this.$store.getters['Config/getClientAccountConfiguration'],
          'gastro.orders.preview_printing.enabled'
        )
      },
      set: function (newValue) {
        this.$store.dispatch('Config/setClientAccountConfigurationValue', {
          path: 'gastro.orders.preview_printing.enabled',
          value: newValue || false
        })
      }
    }
  }
}
</script>
